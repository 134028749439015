<template>
  <div class="regulations-wrapper">
    <van-nav-bar
      fixed
      title="规章制度"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <van-search
      v-model="query.name"
      placeholder="请输入标题"
      @search="onSearch"
      @clear="onCancel"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <div class="search-total">共{{ total }}条记录</div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      class="regulations-list"
      @load="getData"
    >
      <div
        v-for="item in list"
        :key="item.id"
        class="regulations-list-item"
        @click="onClickToDetail(item)"
      >
        <div class="regulations-title">{{ item.name }}</div>
        <div class="regulations-type">
          <span class="regulations-type-first"
            ><i class="iconfont iconfont-qiangzhichengdu"></i
            >{{ item.no || "- -" }}</span
          >
        </div>
        <div class="regulations-publish-date">
          <i class="iconfont iconfont-riqi"></i>{{ item.publishDate || "- -" }}
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getRegulationsPage } from "@/api/psm";
export default {
  name: "Regulations",
  data() {
    return {
      finished: false,
      query: {
        page: 1,
        size: 10,
        name: ""
      },
      total: 0,
      list: [],
      loading: false
    };
  },
  methods: {
    onClickToDetail(item) {
      this.$router.push({
        name: "regulations-detail",
        params: {
          id: item.id
        }
      });
    },
    getData() {
      this.loading = true;
      getRegulationsPage(this.query)
        .then(res => {
          const { list, total } = res;
          if (this.query.page === 1) {
            this.list = [];
          }
          this.total = total;
          this.loading = false;
          if (list.length < this.query.size) {
            this.finished = true;
          } else {
            this.query.page++;
          }
          this.list = this.list.concat(list);
        })
        .catch(e => {
          this.finished = true;
          this.loading = false;
        });
    },
    onClickLeft() {
      this.$router.back();
    },
    onSearch() {
      this.query.page = 1;
      this.getData();
    },
    onCancel() {}
  }
};
</script>

<style lang="scss">
.regulations-wrapper {
  padding-top: 60px;
  background-color: #f3f5fa;
  height: 100%;
  box-sizing: border-box;
  .van-dropdown-menu__bar {
    box-shadow: none;
  }
  .search-total {
    text-align: center;
    font-size: 12px;
    color: #b9bac5;
    padding-top: 6px;
    background-color: #fff;
    margin-top: 1px;
  }
  .regulations-list {
    &-item {
      background-color: #fff;
      margin-bottom: 8px;
      padding: 12px 16px;
      color: #8c8f97;
      font-size: 12px;
      .regulations-title {
        font-size: 16px;
        font-weight: 500;
        color: #2e2e4d;
        margin-bottom: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .regulations-type-first {
        display: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .iconfont {
        font-size: 14px;
        margin-right: 4px;
      }
      .regulations-type {
        .regulations-type-first {
          margin-right: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        margin-bottom: 11px;
      }
    }
  }
}
</style>
